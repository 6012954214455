.container {
    border: 1px solid gray;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    position: relative;
}

.input {
    margin: 10px;
}

.btn {
    cursor: pointer;
    position: relative;
    height: 20px;
    width: 20px;
    border: 0;
    outline: none;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 20px 20px;
    background-color: transparent;
    &:disabled {
        opacity: 0.4;
    }
    &.update {
        background-image: url("./ic_push_update.svg");
    }
    &.create {
        background-image: url("./ic_add_ao.svg");
    }
}
.link {
    position: absolute;
    right: 40px;
    top: 10px;
}
