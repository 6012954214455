.section {
  position: relative;
  margin-top: 2rem;
  padding: 2rem;
  padding-top: 0;
  border-bottom: 2px solid #042437;
}
.btnWrapper {
  position: absolute;
  top: 0;
  left: 8rem;
  display: flex;
  gap: 0.6rem;
  color: #001e57;
  font-weight: 500;
  border-color: #001e57;
}
