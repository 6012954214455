:root {
    --transparentHover: #f0f0f0;

    --attention: #a9431e; 
    --menu: #032537;

    --text: #000000;
    --labelLink: #2e343e;
    --links: #0058bf;
    --placeholder: #babcbf;
    --fieldBorder: #d1d3d5;
    --buttonHover: #e3e5e8;
    --popupBg: #f7f7f7;
    --bg: #ffffff;

    --warning: #fbf1e0;
    --error: #d0021b;
}

strong {
    font-weight: 500;
}
