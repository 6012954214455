.container {
  padding: 20px;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}
.pagination {
  display: flex;
  justify-content: space-between;
}
.table {
  background-color: var(--bg);
  margin-top: 10px;
  overflow-y: auto;
  min-height: calc(100% - 40px);
  position: relative;
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
}
.header {
  font-weight: 400;
  font-size: 24px;
  text-transform: capitalize;
}
.th {
  border-bottom: 2px solid var(--attention);
  display: flex;
  padding: 20px 0;
  font-weight: 500;
  margin-bottom: 5px;
  position: sticky;
  left: 0;
  top: 0;
  background-color: var(--bg);
  z-index: 1;
}
.tbody {
  overflow-x: auto;
}
.row {
  display: flex;
  margin-bottom: 5px;
}
.clusterHeader {
  padding-left: 10px;
  flex: 0 0 150px;
  align-self: center;
}
.cluster {
  padding-left: 10px;
  flex: 0 0 150px;
  align-self: center;
  text-decoration: none;
  color: #0058bf;
  border: none;
  background: transparent;
  display: block;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}
.images {
  display: flex;
  overflow-y: auto;
  flex: 1 1 0;
}
.link {
  display: flex;
  flex-direction: column;
  flex: 0 0 180px;
  margin: 2px;
  text-decoration: none;
  min-width: 0;
}
