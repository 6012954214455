.TrialPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 20px 20px 20px;
  position: relative;
}

.form {
  margin-top: 20px;
}

.field {
  margin-bottom: 10px;
  width: 300px;
  label {
    display: block;
    margin-bottom: 5px;
  }
}

.button {
  margin: 0;
}

.history {
  position: absolute;
  top: 20px;
  right: 20px;
  line-height: 20px;
  display: flex;
}

.historyBtn {
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
  margin-left: 5px;
  top: 3px;
  background: {
    color: transparent;
    image: url("./ic-history.svg");
    size: 20px 20px;
  }
}
